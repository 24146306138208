import React from "react"
import { Link } from "gatsby"
import Prev from './../components/Prev';
import "./../App.css"

export default function Index() {
  return (
    <Prev />
  )
}

