import React from "react"
import styled from "styled-components"
import Logo from "../assets/logo-01w.svg"
import { motion } from "framer-motion"

const Container = styled(motion.div)`
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Title = styled.p`
  color: white;
  margin-top: 5rem;
  text-transform: uppercase;
  font-weight: 200;
  font-family: --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)",
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)",
  },
}

export default function Pre() {
  return (
    <Container>
      <motion.div
        animate={{ opacity: 0.2 }}
        transition={{ repeat: Infinity, repeatType: "reverse", duration: 1.5 }}
      >
        <Logo width="500px" />
      </motion.div>

      <Title>coming soon</Title>
    </Container>
  )
}
